import { DIFFICULTY_LEVEL_MAP, INTEGER_TYPE_MAP } from '@constants/quiz';
import { questionTypes, educatorTypes } from '../constants';

const initialState = {
  question: {},
  options: Array(4).fill({}),
  correctAnswer: [],
  questionUID: null,
  questionType: 1,
  singleSolution: null,
  upperLimit: null,
  lowerLimit: null,
  solutionExplanation: {},
  descriptiveQuestionType: 0,
  difficultyLevel: 1,
  expectedTime: 10,
  hint: {},
  passage: {},
  videoSolution: '',
  isPreviousYearQuestion: 0,
  examYear: {},
  exam: null,
  hasDiagram: 0,
  hasEquation: 0,
  goal: null,
  primaryConcept: null,
  primarySubConcept: null,
  secondaryConcept: null,
  secondarySubConcept: null,
  isActive: false,
  isVerified: false,
  mistakeHints: Array(4).fill({}),
  isCommonMistakes: Array(4).fill(false),
  selectedLanguages: [{ name: 'English', id: 1 }],
  allLanguages: [],
  noPreference: [],
  integerDataType: 0,
  hasPassage: 0,
  hasPartialMarking: 0,
  positiveMarks: 1,
  negativeMarks: 0,
  partialMarkingMultiplier: null,
  isMetaDataSaved: false,
  isIntegerRange: false,
  topicGroup: null,
  topic: null,
  concept: null,
  subConcept: null,
  startedEditing: false,
  learnerIssues: [],
  passageRelatedQuestion: [],
  showQuestionInATG: true
};

const getCorrectAnswerIndices = (answers, correctAnswersList) => {
  const indices = [];
  answers.forEach(({ uid, rank }) => {
    if (correctAnswersList.includes(uid)) {
      indices.push(rank);
    }
  });
  return indices;
};

const isEmpty = (value) =>
  value === undefined || value === '' || value === null;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${questionTypes.FETCH_QUESTION_DATA}_SUCCESS`: {
      const { result } = action;
      const options = [];
      const mistakeHints = [];
      const isCommonMistakes = [];
      (result.answers?.length ? result.answers : Array(4).fill({})).forEach(
        (ans, i) => {
          options[i] = ans.content || {};
          mistakeHints[i] = ans.mistake_hint || {};
          isCommonMistakes[i] = ans.is_common_mistake || false;
        }
      );
      const selectedLanguages = [];
      const languageIDs = Object.keys(result.content).map((string) =>
        parseInt(string, 10)
      );
      const singleSolution = {};
      const singleSolutionLanguageCodes = Object.keys(result.single_solution);
      let isIntegerRange = false;

      if (
        !isEmpty(result.upper_bound?.[singleSolutionLanguageCodes[0]]) ||
        !isEmpty(result.lower_bound?.[singleSolutionLanguageCodes[0]])
      ) {
        isIntegerRange = true;
      }

      state.allLanguages.forEach((language) => {
        if (languageIDs.includes(language.id)) selectedLanguages.push(language);
      });
      singleSolutionLanguageCodes.forEach((languageCode) => {
        singleSolution[languageCode] =
          Number(result.single_solution[languageCode]) ||
          result.single_solution[languageCode];
      });
      const hasPassage =
        result.comprehension &&
        result.comprehension[Object.keys(result.comprehension)[0]]
          ? 1
          : 0;
      return {
        ...state,
        questionUID: result.uid,
        questionType: result.type,
        isActive: result.is_active,
        isVerified: result.is_verified,
        isPublished: result.is_published,
        correctAnswer: getCorrectAnswerIndices(
          result.answers,
          result.correct_answers_list
        ),
        question: result.content,
        descriptiveQuestionType: result.descriptive_question_type,
        difficultyLevel: result.difficulty_level || DIFFICULTY_LEVEL_MAP.EASY,
        expectedTime: result.expected_time,
        hint: result.hint,
        videoSolution: result.video_solution,
        isPreviousYearQuestion: result.exams?.length ? 1 : 0,
        examYear: result.exams
          ? result.exams[result.exams.length - 1]?.selected_date || {}
          : {},
        exam: result.exams
          ? result.exams[result.exams.length - 1]?.exam || null
          : null,
        hasDiagram: result.has_diagram ? 1 : 0,
        hasEquation: result.has_equation ? 1 : 0,
        hasPassage,
        passage: result.comprehension || {},
        singleSolution,
        integerDataType: INTEGER_TYPE_MAP.INTEGER,
        isIntegerRange,
        lowerLimit: result.lower_bound || {},
        upperLimit: result.upper_bound || {},
        primarySubConcept: result.topology,
        secondarySubConcept: result.secondary_concept,
        solutionExplanation: result.solution_explanation,
        goal: result?.goal?.parent,
        options,
        mistakeHints,
        isCommonMistakes,
        selectedLanguages,
        noPreference: languageIDs.includes(-1)
          ? [{ name: 'No preference', id: -1 }]
          : [],
        issues: result.issues,
        learnerIssues: result.learner_issues,
        atg_uid: result.atg_uid,
        ownershipType: result.ownership_type,
        showQuestionInATG: !result.is_blacklisted_for_atg
      };
    }

    case `${educatorTypes.FETCH_ALL_LANGUAGES}_SUCCESS`:
      return { ...state, allLanguages: action.result };

    case `${questionTypes.TOGGLE_QUESTION_STATUS}_SUCCESS`:
      return {
        ...state,
        isActive: action.isActive,
        ...(action.isPublished !== null && {
          isPublished: action.isPublished
        })
      };

    case questionTypes.EDIT_OPTION:
      return {
        ...state,
        startedEditing: true,
        [action.id]: action.value
      };

    case questionTypes.EDIT_CONTENT:
      if (action.languages) {
        const byLanguages = {};

        action.languages.forEach((language) => {
          byLanguages[language.id] = action.value;
        });

        return {
          ...state,
          startedEditing: true,
          [action.id]: {
            ...byLanguages
          }
        };
      }

      return {
        ...state,
        startedEditing: true,
        [action.id]: { ...state[action.id], [action.language]: action.value }
      };

    case questionTypes.EDIT_ANSWER: {
      const { options } = state;
      options[action.option] = {
        ...options[action.option],
        [action.language]: action.value
      };
      return { ...state, startedEditing: true, options: [...options] };
    }

    case questionTypes.EDIT_HINT: {
      const { mistakeHints } = state;
      mistakeHints[action.option] = {
        ...mistakeHints[action.option],
        [action.language]: action.value
      };
      return { ...state, mistakeHints };
    }

    case questionTypes.EDIT_LANGUAGE: {
      let passage = {};
      let question = {};
      let hint = {};
      let solutionExplanation = {};
      let options = [];
      let noPreference = [];
      if (action.incrementNoPreference > 0) {
        const key = (state.noPreference.length + 1) * -1;
        passage = {
          ...state.passage,
          [key]: state.passage[action.newId],
          [action.newId]: state.passage[action.oldId]
        };
        question = {
          ...state.question,
          [key]: state.question[action.newId],
          [action.newId]: state.question[action.oldId]
        };
        hint = {
          ...state.hint,
          [key]: state.hint[action.newId],
          [action.newId]: state.hint[action.oldId]
        };
        solutionExplanation = {
          ...state.solutionExplanation,
          [key]: state.solutionExplanation[action.newId],
          [action.newId]: state.solutionExplanation[action.oldId]
        };
        options = state.options.map((option) => {
          const opt = {
            ...option,
            [key]: option[action.newId],
            [action.newId]: option[action.oldId]
          };
          delete opt[action.oldId];
          return opt;
        });
        noPreference = [
          ...state.noPreference,
          { name: 'No preference', id: (state.noPreference.length + 1) * -1 }
        ];
      } else {
        passage = {
          ...state.passage,
          [action.newId]: state.passage[action.oldId]
        };
        question = {
          ...state.question,
          [action.newId]: state.question[action.oldId]
        };
        hint = {
          ...state.hint,
          [action.newId]: state.hint[action.oldId]
        };
        solutionExplanation = {
          ...state.solutionExplanation,
          [action.newId]: state.solutionExplanation[action.oldId]
        };
        options = state.options.map((option) => {
          const opt = { ...option, [action.newId]: option[action.oldId] };
          delete opt[action.oldId];
          return opt;
        });
        noPreference = state.noPreference;
      }
      if (action.oldId < 0) {
        noPreference = state.noPreference.filter(
          (np) => np.id !== action.oldId
        );
      }
      delete passage[action.oldId];
      delete question[action.oldId];
      delete hint[action.oldId];
      delete solutionExplanation[action.oldId];
      return {
        ...state,
        selectedLanguages: action.value,
        noPreference,
        passage,
        question,
        hint,
        solutionExplanation,
        options
      };
    }

    case questionTypes.MARK_CORRECT: {
      let { correctAnswer } = state;
      if (action.isMultipleCorrect) {
        const index = correctAnswer.indexOf(action.rank);
        if (index === -1) correctAnswer = [...correctAnswer, action.rank];
        else
          correctAnswer = correctAnswer.filter(
            (answer) => answer !== action.rank
          );
      } else correctAnswer = [action.rank];
      return {
        ...state,
        startedEditing: true,
        correctAnswer
      };
    }

    case questionTypes.MARK_IS_COMMON: {
      const { isCommonMistakes } = state;
      const data = [...isCommonMistakes];
      data[action.index] = !data[action.index];

      return { ...state, isCommonMistakes: data };
    }

    case questionTypes.ADD_OPTION: {
      const options = [];
      const mistakeHints = [];
      const isCommonMistakes = [];

      for (let i = 0; i < action.value; i += 1) {
        options.push({});
        mistakeHints.push({});
        isCommonMistakes.push(false);
      }

      return {
        ...state,
        options,
        mistakeHints,
        isCommonMistakes
      };
    }

    case questionTypes.REMOVE_LANGUAGE: {
      const {
        passage,
        question,
        singleSolution,
        lowerLimit,
        upperLimit,
        solutionExplanation,
        hint,
        options,
        mistakeHints
      } = state;

      delete question[action.language.id];
      delete passage[action.language.id];
      delete singleSolution?.[action.language.id];
      delete lowerLimit?.[action.language.id];
      delete upperLimit?.[action.language.id];
      delete solutionExplanation[action.language.id];
      delete hint[action.language.id];
      options.forEach((option) => {
        const newOption = option;
        delete newOption[action.language.id];
      });
      mistakeHints.forEach((mistakeHint) => {
        const newHint = mistakeHint;
        delete newHint[action.language.id];
      });

      return {
        ...state,
        selectedLanguages: state.selectedLanguages.filter(
          (language) => language.id !== action?.language?.id
        ),
        question,
        passage,
        solutionExplanation,
        hint,
        options,
        mistakeHints
      };
    }

    case questionTypes.SET_NUMBER_OPTIONS: {
      const options = state.options.slice(0, action.value);
      const correctAnswer = state.correctAnswer.filter((answer) =>
        options.some((option, index) => index + 1 === answer)
      );
      if (options.length < action.value)
        Array.from(
          { length: action.value - options.length },
          () => ({})
        ).forEach((obj) => options.push(obj));
      return {
        ...state,
        correctAnswer,
        options
      };
    }

    case questionTypes.SETUP_DATA: {
      if (Object.keys(action.data).length)
        return {
          ...state,
          ...action.data,
          partialMarkingMultiplier: action.data.hasPartialMarking
            ? action.data.partialMarkingMultiplier
            : state.partialMarkingMultiplier
        };
      const options = [];
      Array.from({ length: state.options.length }, () => ({})).forEach((obj) =>
        options.push(obj)
      );
      return {
        ...initialState,
        isPreviousYearQuestion: state.isPreviousYearQuestion,
        exam: state.exam,
        examYear: state.examYear,
        questionType: state.questionType,
        integerDataType: state.integerDataType,
        hasPassage: state.hasPassage,
        hasPartialMarking: state.hasPartialMarking,
        passage: state.passage,
        positiveMarks: state.positiveMarks,
        negativeMarks: state.negativeMarks,
        partialMarkingMultiplier: state.partialMarkingMultiplier,
        descriptiveQuestionType: state.descriptiveQuestionType,
        options
      };
    }

    case `${questionTypes.SAVE_QUESTION}_SUCCESS`:
      return {
        ...state,
        startedEditing: false
      };

    case `${questionTypes.FETCH_PASSAGE_RELATED_QUESTIONS}_REQUEST`:
      return { ...state, isSubmitting: true };

    case `${questionTypes.FETCH_PASSAGE_RELATED_QUESTIONS}_SUCCESS`:
      return {
        ...state,
        passageRelatedQuestion: action.result
      };

    case `${questionTypes.FETCH_PASSAGE_RELATED_QUESTIONS}_FAILURE`:
      return { ...state, isSubmitting: false };

    default:
      return state;
  }
};

export default reducer;
