import {
  QUESTION_TYPE_MAP,
  DIFFICULTY_LEVEL_MAP,
  DESCRIPTIVE_QUESTION_TYPES_MAP,
  QUESTION_SUB_TYPE_MAP
} from '@constants/quiz';
import { questionTypes } from '../constants';

const initialState = {
  config: {
    isAutofilled: false,
    questionType: QUESTION_TYPE_MAP.SINGLE,
    questionSubType: QUESTION_SUB_TYPE_MAP.NO_SUBTYPE,
    hasQuestionSubType: false,
    totalQuestions: null,
    totalOptions: 4,
    hasPassage: false,
    hasPartialMarking: false,
    positiveMarks: 1,
    partialMarkingMultiplier: null,
    negativeMarks: 0,
    topology: {
      topicGroup: null,
      topic: null,
      concept: null,
      subConcept: null,
      conceptField: null
    },
    difficultyLevel: DIFFICULTY_LEVEL_MAP.EASY,
    descriptiveQuestionType: DESCRIPTIVE_QUESTION_TYPES_MAP.FACT,
    exam: {},
    examYear: {},
    isPreviousYearQuestion: false,
    hasEquation: false,
    isValid: false,
    isVerified: false
  },
  data: {
    questionUID: null,
    passage: {},
    question: {},
    singleSolution: null,
    isIntegerRange: false,
    lowerLimit: null,
    upperLimit: null,
    solutionExplanation: {},
    correctAnswer: null,
    options: {},
    issues: []
  },
  isDirty: true,
  questionSubTypeOptions: []
};

const reducerMap = {
  [`${questionTypes.UPDATE_QUESTION_CONFIG}`](state, action) {
    const { data } = action;

    return {
      ...state,
      config: {
        ...state.config,
        ...data,
        ...(data.questionType !== state.config.questionType && {
          totalOptions:
            data.totalOptions ||
            state.config.totalOptions ||
            initialState.config.totalOptions
        })
      },
      data: {
        ...state.data,
        ...((data.questionType !== state.config.questionType ||
          data.totalOptions !== state.config.totalOptions) && {
          singleSolution: null,
          isIntegerRange: false,
          lowerLimit: null,
          upperLimit: null,
          options: {},
          correctAnswer:
            data.questionType === QUESTION_TYPE_MAP.SINGLE ? null : {}
        })
      }
    };
  },
  [`${questionTypes.UPDATE_QUESTION_DATA}`](state, action) {
    const { data } = action;

    return {
      ...state,
      isDirty: true,
      data: {
        ...state.data,
        ...data
      }
    };
  },
  [`${questionTypes.FULL_RESET_QUESTION_DATA}`](state) {
    return {
      ...initialState,
      config: {
        ...initialState.config,
        hasQuestionSubType: state.config.hasQuestionSubType
      },
      questionSubTypeOptions: state.questionSubTypeOptions
    };
  },
  [`${questionTypes.RESET_QUESTION_DATA}`](state) {
    return {
      ...state,
      isDirty: false,
      config: {
        ...state.config,
        isAutofilled: false,
        topology: {
          topicGroup: null,
          topic: null,
          concept: null,
          subConcept: null,
          conceptField: null
        },
        isValid: false
      },
      data: {
        ...initialState.data,
        ...(state.config.hasPassage && {
          passage: state.data.passage
        })
      }
    };
  },
  [`${questionTypes.SETUP_CONFIG_FOR_EDITING}`](state, action) {
    const { data = {} } = action;

    return {
      ...state,
      isDirty: false,
      config: {
        ...state.config,
        ...data
      }
    };
  },
  [`${questionTypes.SETUP_QUESTION_FOR_EDITING}`](state, action) {
    const {
      data: { data = {}, config = {} }
    } = action;

    return {
      ...state,
      isDirty: false,
      data: {
        ...initialState.data,
        ...data
      },
      config: {
        ...state.config,
        ...config
      }
    };
  },
  [`${questionTypes.ADD_QUESTION_SUB_TYPE}_SUCCESS`](state, action) {
    const {
      result: { config = {}, questionSubTypeOptions = [] }
    } = action;

    return {
      ...state,
      config: {
        ...state.config,
        ...config
      },
      questionSubTypeOptions
    };
  }
};

const reducer = (state = initialState, action) => {
  const actionHandler = reducerMap[action.type];
  return actionHandler ? actionHandler(state, action) : state;
};

export default reducer;
